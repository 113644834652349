import React, { useState, useEffect } from 'react';
import { RetrieveQuestions, CreateInstallerCheckList } from '@frontend/services/post-install';
import { QuestionType, AnswerType, IQuestions, IAnswerOptions, int, ICreateInstallerCheckList } from '@common.abstractions';
import TopBar from '../misc/top-bar/TopBar';
import { toast } from 'react-toastify';
import { Equals } from '@common.api';
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom';

export default function InstallerCheckListForm() {
    const history = useHistory();
    const { postInstallId } = useParams<{ postInstallId: string }>();
    const [questions, setQuestions] = useState<IQuestions[]>([]);
    const [dropdownFreeFormResponses, setDropdownFreeFormResponses] = useState<{ [key: number]: string }>({});
    const [userResponses, setUserResponses] = useState<{ question: string; answer: string }[]>([]);

    const loadQuestions = async () => {
        const response = await RetrieveQuestions({
            relations: ['answerOptions'],
            filters: {
                type: Equals(QuestionType.INSTALL),
            }
        });

        if (!response) return toast.error('Failed to load questions');
        response.sort((a, b) => a.id - b.id);

        setQuestions(response);
    }

    const handleDropdownFreeFormSelection = (questionId: int, selectedValue: string) => {
        setUserResponses((prevResponses) => {
            const selectedQuestion = questions.find((q) => q.id === questionId);

            // Remove any previous responses for the same question
            const updatedResponses = prevResponses.filter(response => response.question !== selectedQuestion?.text);

            const answer = selectedQuestion?.answerOptions?.find((option) => option.text === selectedValue);
            if (answer) {
                updatedResponses.push({
                    question: selectedQuestion!.text || '',
                    answer: answer.text,
                });
            }

            return updatedResponses;
        });

        setDropdownFreeFormResponses({
            ...dropdownFreeFormResponses,
            [questionId]: selectedValue,
        });
    }

    const handleDropdownSelection = (questionId: int, selectedValue: string) => {
        const selectedQuestion = questions.find((q) => q.id === questionId);

        setUserResponses((prevResponses) => {
            if (selectedQuestion) {
                const answer = selectedQuestion.answerOptions?.find((option) => option.text === selectedValue);
                if (answer) {
                    // Remove any previous responses for the same question
                    const updatedResponses = prevResponses.filter(response => {
                        return response.question !== selectedQuestion.text;
                    });

                    // Add the final response.
                    updatedResponses.push({
                        question: selectedQuestion.text,
                        answer: answer.text,
                    });

                    return updatedResponses;
                }
            }

            // For other values or if the answer is not found, no change in responses.
            return prevResponses;
        });
    }

    const handleFreeFormInput = (questionId: int, inputValue: string) => {
        setUserResponses((prevResponses) => {
            const selectedQuestion = questions.find((q) => q.id === questionId);

            if (selectedQuestion) {
                // Remove any previous responses for the same question
                const updatedResponses = prevResponses.filter(response => {
                    return response.question !== selectedQuestion.text;
                });

                // Add the final response.
                updatedResponses.push({
                    question: selectedQuestion.text,
                    answer: inputValue,
                });

                return updatedResponses;
            }

            // For other values, no change in responses.
            return prevResponses;
        });
    }

    const handleYesNoSelection = (questionId: int, selectedValue: string) => {
        setUserResponses((prevResponses) => {
            const updatedResponses = prevResponses.filter(response => {
                // Remove any previous responses for the same question
                return response.question !== questions.find(q => q.id === questionId)?.text;
            });

            updatedResponses.push({
                question: questions.find(q => q.id === questionId)?.text || '',
                answer: selectedValue,
            });

            return updatedResponses;
        });
    }

    const handleResponseSubmission = async () => {
        if (window.confirm('Are you sure you want to submit?')) {
            const params: ICreateInstallerCheckList = {
                postInstallId: int.parse(postInstallId),
                response: userResponses,
            }

            const installerCheckList = await CreateInstallerCheckList(params);
            if (!installerCheckList) return toast.error('Something went wrong');

            setUserResponses([]);
            toast.success('Submission received');
            history.push('/calendar');
        };
    }

    const renderInput = (question: IQuestions) => {
        const containerStyle = 'w-full mb-2 flex flex-col items-center'

        switch (question.answerType) {
            case AnswerType.DD:
                return (
                    <div className={containerStyle} key={question.id} >
                        <Select
                            className='w-full sm:w-2/3 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                            onChange={(val) => handleDropdownSelection(question.id, val!.value)}
                            options={
                                question.answerOptions?.sort((a, b) => a.id - b.id)
                                    .map((option: IAnswerOptions) => ({
                                        value: option.text,
                                        label: option.text,
                                    }))
                            }
                        />
                    </div>
                );
            case AnswerType.DD_FF:
                return (
                    <div className={containerStyle}>
                        <Select
                            className='w-full sm:w-2/3 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                            onChange={(val) => {
                                const selectedValue = val?.value;
                                handleDropdownFreeFormSelection(question.id, selectedValue!);

                                if (selectedValue === 'Yes') {
                                    setUserResponses((prevResponses) => [
                                        ...prevResponses,
                                        {
                                            question: question.text,
                                            answer: 'Yes',
                                        },
                                    ]);
                                } else if (selectedValue === 'No') {
                                    const selectedQuestion = questions.find((q) => q.id === question.id);
                                    if (selectedQuestion) {
                                        const answer = selectedQuestion.answerOptions?.find((option) => option.text === selectedValue);
                                        if (answer) {
                                            setUserResponses((prevResponses) => [
                                                ...prevResponses,
                                                {
                                                    question: selectedQuestion.text,
                                                    answer: answer.text,
                                                },
                                            ]);
                                        }
                                    }
                                }
                            }}
                            options={[
                                {
                                    value: 'Yes',
                                    label: 'Yes',
                                },
                                {
                                    value: 'No',
                                    label: 'No',
                                }
                            ]}
                        />
                        {dropdownFreeFormResponses[question.id] === 'No' &&
                            <div className='w-full mt-2'>
                                <label className='text-sm block mb-1'>If not, why?</label>
                                <input
                                    className='w-full px-3 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                                    type="text"
                                    onBlur={(e) => handleFreeFormInput(question.id, e.target.value)}
                                />
                            </div>}
                    </div>
                );
            case AnswerType.FF:
                return (
                    <div className={containerStyle} key={question.id}>
                        <input
                            className='w-full sm:w-2/3 px-3 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                            type="text"
                            onBlur={(e) => handleFreeFormInput(question.id, e.target.value)}
                        />
                    </div>
                );
            case AnswerType.YN:
                return (
                    <div className={containerStyle} key={question.id} >
                        <Select
                            className='w-full sm:w-2/3 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                            options={[
                                {
                                    value: 'Yes',
                                    label: 'Yes',
                                },
                                {
                                    value: 'No',
                                    label: 'No',
                                }
                            ]}
                            onChange={(val) => handleYesNoSelection(question.id, val!.value)}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        loadQuestions();
    }, []);

    return (
        <div className='w-full h-full flex flex-col bg-gray-100'>
            <TopBar title="Installer Checklist" />
            <div className='flex flex-col justify-center items-center flex-1 overflow-y-auto px-2 py-4 md:p-4'>
                <div className="w-full max-w-lg mx-auto bg-white shadow-md rounded-lg p-4 md:p-6 overflow-y-auto">
                    {questions.map((question, index) => (
                        <div key={question.id} className={`mb-4 p-4 ${index % 2 === 0 ? 'bg-blue-100' : 'bg-gray-100'} rounded-md`}>
                            <label className='block text-sm md:text-md font-semibold mb-2'>{question.text}</label>
                            {renderInput(question)}
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleResponseSubmission}
                        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mt-4 w-full transition duration-300"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};