import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom-v5-compat";
import { CreateCompany as CreateCompanyService, RetrieveCompanies } from "../../../services/companies";
import Select from "react-select";
import { toast } from "react-toastify";
import {
    BuildingType,
    CompanyType,
    float,
    ICompany,
    ICreateCompanyParams,
    ICreateDiscountPackageParams,
    int,
    IUser,
    ProductCertification,
    ProductLine,
} from "@common.abstractions";
import { cleanPhoneNumber, moneyStringToCents } from "@common.tools";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import { RetrieveUser, RetrieveUsers } from "@frontend/services/users";
import { Equals, Exists, Not } from "@common.api";
import { UpdateDealerContract } from "@frontend/services/contract";

export default function CreateCompany() {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        watch,
        setValue,
        getValues,
        control,

        formState: { errors },
    } = useForm();
    const [errMessage, setErrMessage] = useState<string>();
    const [users, setUsers] = useState<IUser[]>();
    const [assignedUser, setAssignedUser] = useState<IUser>();
    const [dealers, setDealers] = useState<ICompany[]>();
    const [parentCompanyId, setParentCompanyId] = useState<int>();
    const [isParentCompany, setIsParentCompany] = useState<boolean>(false);

    const packageLastPaidDate = new Date(watch("packageLastPaidDate", new Date()));
    const watchCompanyType = watch("companyType", CompanyType.DEALER);

    useEffect(() => {
        if (packageLastPaidDate) {
            const nextJan = new Date(new Date().getFullYear() + 1, 0, 1).toISOString().split("T")[0];
            setValue("packageExpirationDate", nextJan);
        }
    }, [packageLastPaidDate]);

    useEffect(() => {
        if (watchCompanyType === CompanyType.DEALER) {
            setValue("timeZone", undefined);
        }
    }, [watchCompanyType]);

    const loadUsers = async () => {
        const users = await RetrieveUsers({
            filters: {
                deletedAt: Not(Exists()),
                company: {
                    companyType: Equals(CompanyType.BRANCH),
                    id: Equals(int(40)),
                },
            },
            relations: ["company"],
        });
        setUsers(users);
    };

    const findUser = async (userId: int) => {
        const user = await RetrieveUser(userId);
        if (!user) {
            console.log("Could not retrieve user");
            return;
        }
        setAssignedUser(user);
    };

    const loadDealers = async () => {
        const parentCompanies = await RetrieveCompanies({
            filters: {
                companyType: Equals(CompanyType.DEALER),
                isParentCompany: Equals(true),
            },
        });
        setDealers(parentCompanies);
    }

    useEffect(() => {
        loadUsers();
        loadDealers();
    }, []);

    const submit = async (values: any) => {
        if (!values.timeZone && values.companyType === CompanyType.BRANCH) {
            toast.error("Branches need a timezone selected");
            return;
        }

        if (values.timeZone && values.timeZone !== "") {
            const timeZoneNameAndAbbr = values.timeZone.split(", ");
            values.timeZone = { zoneName: timeZoneNameAndAbbr[0], abbreviation: timeZoneNameAndAbbr[1] };
        } else {
            values.timeZone = null;
        }

        const discountPackageParams: ICreateDiscountPackageParams = {
            name: values.packageName,
            annualMoq: Number(values.packageMOQ) as float,
            discountPercent: Number(values.packageDiscountPercent) as float,
            startDate: new Date(Date.parse(values.packageLastPaidDate)),
            endDate: new Date(Date.parse(values.packageExpirationDate)),
        };

        values.phone = cleanPhoneNumber(values.phone);
        values.primaryServiceCharge = (values.primaryServiceCharge as number) * 100;
        values.secondaryServiceCharge = (values.secondaryServiceCharge as number) * 100;

        //this is a temporary work around while there is only one certification
        let arr = new Array();
        if (values.productCertifications)
            arr.push(values.productCertifications);
        values.productCertifications = arr;

        const companyParams: ICreateCompanyParams = {
            address: values.address,
            companyType: values.companyType,
            name: values.name,
            phone: values.phone,
            personalPhone: values.personalPhone ? values.personalPhone : null,
            email: values.email,
            buildingTypes: values.buildingTypes,
            facebookUrl: values.facebookUrl,
            instagramUrl: values.instagramUrl,
            websiteUrl: values.websiteUrl,
            productLines: values.productLines,
            productCertifications: values.productCertifications,
            timeZone: values.timeZone,
            owner: assignedUser,
            parentCompanyId: parentCompanyId ?? null,
            isParentCompany: isParentCompany,
            monthlyMarketingSpend: values.monthlyMarketingSpend ? moneyStringToCents(values.monthlyMarketingSpend) : null,
            marketingZipCodes: values.marketingZipCodes ? values.marketingZipCodes.split(',').map((el: any) => el.trim()) : []
        };

        try {
            const company = await CreateCompanyService(companyParams, discountPackageParams);
            if (company) {
                toast("Company Created");
                if (company.companyType === CompanyType.DEALER && values.pandadocId) {
                    await UpdateDealerContract(values.pandadocId, { companyId: company.id })
                }
                return navigate("/companies/" + company.id, { replace: true });
            }
            toast.error("Company Creation Failed");
            setErrMessage("Create company failed");
        } catch (err) {
            setErrMessage("Failed: " + err);
        }
    };

    return (
        <div className="flex flex-col max-h-full">
            <TopBar title="Create Company" />
            <form onSubmit={handleSubmit(submit)}>
                {/* Company Info */}
                <div className="form_slide">
                    <h2 className="title form_title">Company Information</h2>

                    <div className="form_group">
                        <div>
                            <label>Company Name</label>
                            <input
                                className={`form-control ${errors.name && "form_outline_error"}`}
                                type="text"
                                placeholder="Company Name"
                                {...register("name", { required: "Required" })}
                            />
                            {errors.name && <div className="form_input_error">{errors.name.message?.toString()}</div>}
                        </div>
                        <div>
                            <label>Company Type</label>
                            <select
                                {...register("companyType", { required: "Required" })}
                                defaultValue={CompanyType.DEALER}
                            >
                                <option value={CompanyType.DEALER}>Dealer</option>
                                <option value={CompanyType.BRANCH}>Branch</option>
                            </select>
                            {errors.companyType && (
                                <div className="form_input_error">{errors.companyType.message?.toString()}</div>
                            )}
                        </div>
                    </div>

                    <div className="form_group">
                        <div>
                            <label>Phone Number</label>
                            <input
                                className={`form-control ${errors.phone && "form_outline_error"}`}
                                type="tel"
                                placeholder="Company Phone Number"
                                {...register("phone", {
                                    required: "Required",
                                    validate: (val) => cleanPhoneNumber(val) !== undefined,
                                })}
                            />

                            {errors.phone && errors.phone.type === "required" && (
                                <div className="form_input_error">Required</div>
                            )}
                            {errors.phone && errors.phone.type === "validate" && (
                                <div className="form_input_error">Phone number is invalid</div>
                            )}
                        </div>
                        <div>
                            <label>Personal Number</label>
                            <input
                                className={`form-control ${errors.personalPhone && "form_outline_error"}`}
                                type="tel"
                                placeholder="optional"
                                {...register("personalPhone")}
                            />
                        </div>
                    </div>

                    <div className="form_group">
                        <div>
                            <label>Email</label>
                            <input
                                className={`form-control ${errors.email && "form_outline_error"}`}
                                type="text"
                                placeholder="Company Email Address"
                                {...register("email")}
                            />

                            {errors.email && errors.email.type === "required" && (
                                <div className="form_input_error">Required</div>
                            )}
                            {errors.email && errors.email.type === "validate" && (
                                <div className="form_input_error">Email invalid</div>
                            )}
                        </div>
                    </div>

                    <div className="form_group">
                        <div>
                            <label>Street Address</label>
                            <input
                                className={`form-control ${errors.address && (errors.address as any).streetAddress && "form_outline_error"
                                    }`}
                                type="text"
                                placeholder="Street Address"
                                {...register("address.streetAddress", { required: "Required" })}
                            />
                            {errors.address && (errors.address as any).streetAddress && (
                                <div className="form_input_error">{(errors.address as any).streetAddress.message}</div>
                            )}
                        </div>
                        <div>
                            <label>City</label>
                            <input
                                className={`form-control ${errors.address && (errors.address as any).city && "form_outline_error"
                                    }`}
                                type="text"
                                placeholder="City"
                                {...register("address.city", { required: "Required" })}
                            />
                            {errors.address && (errors.address as any).city && (
                                <div className="form_input_error">{(errors.address as any).city.message}</div>
                            )}
                        </div>
                    </div>

                    <div className="form_group">
                        <div>
                            <label>State/Region</label>
                            <input
                                className={`form-control ${errors.address && (errors.address as any).state && "form_outline_error"
                                    }`}
                                type="text"
                                placeholder="Two-Letter Abbreviation"
                                {...register("address.state", { required: "Required" })}
                            />
                            {errors.address && (errors.address as any).state && (
                                <div className="form_input_error">{(errors.address as any).state.message}</div>
                            )}
                        </div>
                        <div>
                            <label>Postal Code</label>
                            <input
                                className={`form-control ${errors.address && (errors.address as any).zip && "form_outline_error"
                                    }`}
                                type="text"
                                placeholder="Postal Code"
                                {...register("address.zip", { required: "Required" })}
                            />
                            {errors.address && (errors.address as any).zip && (
                                <div className="form_input_error">{(errors.address as any).zip.message}</div>
                            )}
                        </div>
                        <div>
                            <label>Country/Region</label>
                            <input
                                className={`form-control ${errors.address && (errors.address as any).country && "form_outline_error"
                                    }`}
                                type="text"
                                placeholder="Country/Region"
                                {...register("address.country", { required: "Required" })}
                            />
                            {errors.address && (errors.address as any).country && (
                                <div className="form_input_error">{(errors.address as any).country.message}</div>
                            )}
                        </div>
                    </div>

                    {watchCompanyType === CompanyType.BRANCH && (
                        <div className="form_group">
                            <div>
                                <label>TimeZone</label>
                                <select
                                    className={`form-control ${errors.timeZone && "form_outline_error"}`}
                                    {...register("timeZone")}
                                >
                                    <option value="">Select</option>
                                    <option value="US/Pacific, PT">Pacific Time (UTC - 08:00)</option>
                                    <option value="US/Mountain, MT">Mountain Time (UTC - 07:00)</option>
                                    <option value="US/Arizona, MST">Arizona (UTC - 07:00)</option>
                                    <option value="US/Central, CT">Central Time (UTC - 06:00)</option>
                                    <option value="US/Eastern, ET">Eastern Time (UTC - 05:00)</option>
                                </select>
                            </div>
                        </div>
                    )}

                    {watchCompanyType === CompanyType.DEALER && (
                        <div>
                            <div className="form_group">
                                <div>
                                    <label>Assigned Dealer Manager</label>
                                    <Select
                                        options={users?.map((user) => ({
                                            value: user.accountId,
                                            label: `${user.firstname} ${user.lastname}`,
                                        }))}
                                        onChange={(val) => findUser(int.parse(val?.value))}
                                    />
                                </div>
                            </div>
                            <div className="form_checkbox_container">
                                <div className="form_checkbox">
                                    <label className="form-check-label">Parent Company</label>
                                    <input className="form-check-input" type="checkbox" checked={isParentCompany} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsParentCompany(e.target.checked)}></input>
                                </div>
                            </div>

                            {!isParentCompany && (
                                <div>
                                    <div className="form_group">
                                        <div>
                                            <label>Assigned Parent Company</label>
                                            <Select
                                                options={dealers?.map((dealer) => ({
                                                    value: dealer.id,
                                                    label: dealer.name,
                                                }))}
                                                onChange={(val) => setParentCompanyId(int.parse(val?.value))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="form_group">
                                <div>
                                    <label>Pandadoc Id</label>
                                    <input
                                        className={`form-control ${errors.pandadocId && "form_outline_error"}`}
                                        type="text"
                                        placeholder="New Dealer Discount Program Agreement"
                                        {...register("pandadocId")}
                                    />

                                </div>
                            </div>
                        </div>
                    )}

                    <div className="form_group">
                        <div>
                            <label>Website Link</label>
                            <input
                                className={`form-control ${errors.websiteUrl && "form_outline_error"}`}
                                type="url"
                                placeholder="Website Url"
                                {...register("websiteUrl")}
                            />
                        </div>
                    </div>

                    <div className="form_group">
                        <div>
                            <label>Facebook Link</label>
                            <input
                                className={`form-control ${errors.facebookUrl && "form_outline_error"}`}
                                type="url"
                                placeholder="Facebook Url"
                                {...register("facebookUrl")}
                            />
                        </div>
                    </div>

                    <div className="form_group">
                        <div>
                            <label>Instagram Link</label>
                            <input
                                className={`form-control ${errors.instagramUrl && "form_outline_error"}`}
                                type="url"
                                placeholder="Instagram Url"
                                {...register("instagramUrl")}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="monthlyMarketingSpend" className="block text-sm font-medium text-gray-500">
                        Monthly Marketing Spend:
                    </label>
                    <input
                        min={0}
                        type="number"
                        step={"any"}
                        id="monthlyMarketingSpend"
                        className="form-input mt-1 block w-full rounded-md border-grey-100 border-2 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                        {...register('monthlyMarketingSpend')}
                        placeholder="optional"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="marketingZipCodes" className="block text-sm font-medium text-gray-700">
                        Marketing Zip Codes:
                    </label>
                    <input
                        type="text"
                        id="marketingZipCodes"
                        className="form-input mt-1 block w-full rounded-md border-grey-100 border-2 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                        {...register('marketingZipCodes')}
                        placeholder="comma separated"
                    />
                </div>

                {/* Service Info */}
                <div className="form_slide">
                    <h2 className="title form_title">Service Information</h2>

                    <div className="form_group">
                        <div className={`form_checkbox_wrapper ${errors.buildingTypes && "form_outline_error"}`}>
                            <label>
                                Targets
                                {errors.buildingTypes && <div className="form_input_error">Required</div>}
                            </label>
                            <div className="form_checkbox_container">
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="targetsResidential">
                                        Residential
                                    </label>
                                    <input
                                        id="targetsResidential"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Targets Residential Customers"
                                        {...register("buildingTypes", {
                                            validate: () => getValues("buildingTypes").length !== 0,
                                        })}
                                        value={BuildingType.RESIDENTIAL}
                                    />
                                </div>
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="targetsCommercial">
                                        Commercial
                                    </label>
                                    <input
                                        id="targetsCommercial"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Targets Commercial Customers"
                                        {...register("buildingTypes", {
                                            validate: () => getValues("buildingTypes").length !== 0,
                                        })}
                                        value={BuildingType.COMMERCIAL}
                                    />
                                </div>
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="targetsMunicipal">
                                        Municipal
                                    </label>
                                    <input
                                        id="targetsMunicipal"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Targets Municipal Customers"
                                        {...register("buildingTypes", {
                                            validate: () => getValues("buildingTypes").length !== 0,
                                        })}
                                        value={BuildingType.MUNICIPAL}
                                    />
                                </div>
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="targetsIndustrial">
                                        Industrial
                                    </label>
                                    <input
                                        id="targetsIndustrial"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Targets Industrial Customers"
                                        {...register("buildingTypes", {
                                            validate: () => getValues("buildingTypes").length !== 0,
                                        })}
                                        value={BuildingType.INDUSTRIAL}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form_group">
                        <div className={`form_checkbox_wrapper ${errors.productLines && "form_outline_error"}`}>
                            <label>
                                Product Lines
                                {errors.productLines && <div className="form_input_error">Required</div>}
                            </label>

                            <div className="form_checkbox_container">
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="dealsClassic">
                                        Classics
                                    </label>

                                    <input
                                        id="dealsClassic"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Deals Classic"
                                        {...register("productLines", {
                                            validate: () => getValues("productLines").length !== 0,
                                        })}
                                        value={ProductLine.CLASSIC}
                                    />
                                </div>
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="dealsColorChanging">
                                        Color Changing
                                    </label>
                                    <input
                                        id="dealsColorChanging"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Deals Color Changing"
                                        {...register("productLines", {
                                            validate: () => getValues("productLines").length !== 0,
                                        })}
                                        value={ProductLine.COLOR_CHANGING}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form_group">
                        <div className={`form_checkbox_wrapper ${errors.productCertifications && "form_outline_error"}`}>
                            <label>
                                Product Certifications
                                {errors.productCertifications && <div className="form_input_error">Required</div>}
                            </label>

                            <div className="form_checkbox_container">
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="certs24V">
                                        24V Permit
                                    </label>

                                    <input
                                        id="certs24V"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="24V Permit"
                                        {...register("productCertifications", {
                                            validate: () => getValues("productCertifications").length !== 0,
                                        })}
                                        value={ProductCertification.PERMIT24V}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Package Info */}
                <div className="form_slide">
                    <h2 className="title form_title">Package Information</h2>

                    <div className="form_group">
                        <div>
                            <label>Package Name</label>
                            <input
                                className={`form-control ${errors.packageName && "form_outline_error"}`}
                                type="text"
                                {...register("packageName", { required: "Required" })}
                                placeholder="Required"
                            />
                            {errors.packageName && (
                                <div className="form_input_error">{errors.packageName?.message?.toString()}</div>
                            )}
                        </div>
                        <div>
                            <label>Minimum Order Qty</label>
                            <input
                                className={`form-control ${errors.packageMOQ && "form_outline_error"}`}
                                type="number"
                                min="0"
                                step="1"
                                {...register("packageMOQ", { required: "Required" })}
                                placeholder="required"
                            />
                            {errors.packageMOQ && (
                                <div className="form_input_error">{errors.packageMOQ?.message?.toString()}</div>
                            )}
                        </div>
                        <div>
                            <label>Discount Percent</label>
                            <input
                                className={`form-control ${errors.packageDiscountPercent && "form_outline_error"}`}
                                type="number"
                                min="0"
                                step="1"
                                {...register("packageDiscountPercent", { required: "Required" })}
                                placeholder="e.g. 30"
                            />
                            {errors.packageDiscountPercent && (
                                <div className="form_input_error">
                                    {errors.packageDiscountPercent?.message?.toString()}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label htmlFor="packageLastPaidDate">Package Paid Date</label>
                            <input
                                id="packageLastPaidDate"
                                className={`form-control ${errors.packageLastPaidDate && "form_outline_error"}`}
                                type="date"
                                defaultValue={new Date().toISOString().split("T")[0]}
                                {...register("packageLastPaidDate", { required: "Required" })}
                            />
                            {errors.packageLastPaidDate && (
                                <div className="form_input_error">
                                    {errors.packageLastPaidDate?.message?.toString()}
                                </div>
                            )}
                        </div>
                        <div>
                            <label htmlFor="packageExpirationDate">Package Expiration Date</label>
                            <input
                                id="packageExpirationDate"
                                className={`form-control ${errors.packageExpirationDate && "form_outline_error"}`}
                                type="date"
                                {...register("packageExpirationDate", { required: "Required" })}
                            />
                            {errors.packageExpirationDate && (
                                <div className="form_input_error">
                                    {errors.packageExpirationDate?.message?.toString()}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Documents Info
                    <div className="form_slide">
                        <h3 className="mb-3">Document Information</h3>

                        <div className="row m-2">
                            <div className="col-8">
                                <div className="row">
                                    <label className="col-4 col-form-label my-auto text-right" htmlFor="businessLicenseNumber">Business License Number :</label>
                                    <input
                                        className={`col-8 form-control ${errors.businessLicenseNumber && "form_outline_error"}`}
                                        type="text"
                                        placeholder="Business License Number"
                                        name="businessLicenseNumber"
                                        ref={register({ required: "Required" })}
                                    />
                                    {errors.businessLicenseNumber && <div className="form_input_error">{errors.businessLicenseNumber.message}</div>}
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <label className="col-6 col-form-label my-auto text-right" htmlFor="businessLicenseExpirationDate">Expiration Date :</label>
                                    <input
                                        id="businessLicenseExpirationDate"
                                        className={`col form-control ${errors.businessLicenseExpirationDate && "form_outline_error"}`}
                                        type="date"
                                        name="businessLicenseExpirationDate"
                                        ref={register({ required: "Required" })}
                                    />
                                    {errors.businessLicenseExpirationDate && <div className="form_input_error">{errors.businessLicenseExpirationDate.message}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="row m-2">
                            <div className="col-8">
                                <div className="row">
                                    <label className="col-4 col-form-label my-auto text-right" htmlFor="insurancePolicyNumber">Insurance Policy Number :</label>
                                    <input
                                        className={`col form-control ${errors.insurancePolicyNumber && "form_outline_error"}`}
                                        type="text"
                                        placeholder="Insurance Policy Number"
                                        name="insurancePolicyNumber"
                                        ref={register({ required: "Required" })}
                                    />
                                    {errors.insurancePolicyNumber && <div className="form_input_error">{errors.insurancePolicyNumber.message}</div>}
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <label className="col-6 col-form-label my-auto text-right" htmlFor="insurancePolicyExpirationDate">Expiration Date :</label>
                                    <input
                                        id="insurancePolicyExpirationDate"
                                        className={`col form-control ${errors.insurancePolicyExpirationDate && "form_outline_error"}`}
                                        type="date"
                                        name="insurancePolicyExpirationDate"
                                        ref={register({ required: "Required" })}
                                    />
                                    {errors.insurancePolicyExpirationDate && <div className="form_input_error">{errors.insurancePolicyExpirationDate.message}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="row m-2">
                            <div className="col-8">
                                <div className="row">
                                    <label className="col-4 col-form-label my-auto text-right" htmlFor="contractorLicenseNumber">Contractor License Number :</label>
                                    <input
                                        className={`col form-control ${errors.contractorLicenseNumber && "form_outline_error"}`}
                                        type="text"
                                        placeholder="Contractor License Number"
                                        name="contractorLicenseNumber"
                                        ref={register}
                                    />
                                    {errors.contractorLicenseNumber && <div className="form_input_error">{errors.contractorLicenseNumber.message}</div>}
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <label className="col-6 col-form-label my-auto text-right" htmlFor="contractorLicenseExpirationDate">Expiration Date :</label>
                                    <input
                                        id="contractorLicenseExpirationDate"
                                        className={`col form-control ${errors.contractorLicenseExpirationDate && "form_outline_error"}`}
                                        type="date"
                                        name="contractorLicenseExpirationDate"
                                        ref={register({ required: !isEmpty(contractorLicenseNumber) })}
                                    />
                                    {errors.contractorLicenseExpirationDate && <div className="form_input_error">{errors.contractorLicenseExpirationDate.message}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="row m-2">
                            <div className="col-8">
                                <div className="row">
                                    <label className="col-4 col-form-label my-auto text-right" htmlFor="bondNumber">Bond Number :</label>
                                    <input
                                        className={`col form-control ${errors.bondNumber && "form_outline_error"}`}
                                        type="text"
                                        placeholder="Bond Number"
                                        name="bondNumber"
                                        ref={register}
                                    />
                                    {errors.bondNumber && <div className="form_input_error">{errors.bondNumber.message}</div>}
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <label className="col-6 col-form-label my-auto text-right" htmlFor="bondExpirationDate">Expiration Date :</label>
                                    <input
                                        id="bondExpirationDate"
                                        className={`col form-control ${errors.bondExpirationDate && "form_outline_error"}`}
                                        type="date"
                                        name="bondExpirationDate"
                                        ref={register({ required: !isEmpty(bondNumber) })}
                                    />
                                    {errors.bondExpirationDate && <div className="form_input_error">{errors.bondExpirationDate.message}</div>}
                                </div>
                            </div>
                        </div>
                    </div> */}

                {errMessage && (
                    <div className="form_input_error">
                        <h4>Submission Failed </h4>
                        <br />
                        {errMessage && <p>{`${errMessage}`}</p>}
                    </div>
                )}

                <button type="submit" className="btn bg-theme_blue text-white mt-8 px-20 max-w-xl mx-auto">
                    Submit
                </button>
            </form>
        </div>
    );
}
