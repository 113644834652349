import AuthContext from '../../contexts/AuthContext'
import React, { useEffect, useState } from 'react'
import TopBar from '../misc/top-bar/TopBar';
import { FinancingCompany, ICreatePostInstall, IInstall, int } from '@common.abstractions';
import { RetrieveInstalls } from '@frontend/services/installs';
import Select from "react-select";
import { toast } from 'react-toastify';
import { CreatePostInstall, RequestSunLightPayment } from '@frontend/services/post-install';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Between } from '@common.api';
import { useHistory } from 'react-router-dom';

export default function InstallerTool() {
    const authContext = React.useContext(AuthContext);
    const history = useHistory();

    const [installs, setInstalls] = useState<IInstall[]>();
    const [lightsInstalledList, setLightInstalledList] = useState<{ ft: int; serialNumber: string | null; }[]>([]);
    const [serialNumber, setSerialNumber] = useState<string | undefined>();
    const [ftInstalled, setFtInstalled] = useState<string>('');
    const [installId, setInstallId] = useState<int>();
    const [jobCompleted, setJobCompleted] = useState<boolean>(false);
    const [selectedInstall, setSelectedInstall] = useState<IInstall>();

    const loadInstalls = async () => {
        const today = new Date();
        const last14Days = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);
        const next14Days = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);

        const response = await RetrieveInstalls({
            filters: {
                installDate: Between(last14Days, next14Days),
            }
        });
        if (!response) {
            console.log('Could not retrieve installs');
            return;
        }
        setInstalls(response.values);
    }

    const submitForm = async () => {
        const params: ICreatePostInstall = {
            lightsInstalled: lightsInstalledList,
            installerAccountId: authContext!.user?.accountId!,
            installId: installId!,
            collectedPayment: null,
            paymentNotes: null,
        }
        const response = await CreatePostInstall(params, jobCompleted);
        if (!response) {
            toast.error('Error - submission failed');
            return;
        }

        toast.success('Your submission has been received');
        setLightInstalledList([]);
        await triggerPaymentRequest();

        if (jobCompleted) {
            history.push(`/installerTool/${response.id}/checklist`)
        }
    }

    const createLightsInstalled = (serialNumber: string | null, ft: int) => {
        const lightsInstalled = {
            serialNumber: serialNumber ? serialNumber : null,
            ft,
        };

        setLightInstalledList([...lightsInstalledList, lightsInstalled]);
        setSerialNumber('');
        setFtInstalled('');
    };

    const deleteLightsInstalled = (lightsInstall: { ft: int; serialNumber: string | null; }) => {
        const newList = lightsInstalledList.filter((el) => el !== lightsInstall);
        setLightInstalledList(newList);
    };

    const toggleJobCompleted = () => {
        jobCompleted ? setJobCompleted(false) : setJobCompleted(true);
    }

    const triggerPaymentRequest = async () => {
        if (selectedInstall?.financingCompany === FinancingCompany.SUNLIGHT_FINANCIAL_SERVICES) {
            await RequestSunLightPayment(selectedInstall!.loanNumber!);
            toast.success('Payment request sent successfully');
        }
    };

    useEffect(() => {
        loadInstalls();
    }, [])

    return (
        <div className="h-full w-full overflow-auto">
            <TopBar title="Footage Tracker" />

            <div className="flex flex-col items-center py-4">
                <Select
                    className="w-full md:w-96 mb-5 border border-gray-300 rounded-md shadow-sm"
                    placeholder="Search..."
                    options={installs?.map((el) => ({
                        value: el.id,
                        label: `(${el.id}) ${el.customer.firstName} ${el.customer.lastName} - ${el.customer.address}`,
                    }))}
                    onChange={(val) => {
                        setInstallId(val?.value);
                        setSelectedInstall(installs?.find((el) => el.id === val?.value));
                    }}
                />

                <div className="flex flex-col md:flex-row mt-5 p-2 text-xl items-center bg-white rounded-md shadow">
                    <div className="flex flex-row items-center mb-2 md:mb-0 md:mr-4">
                        <label className="italic text-gray-600 mr-2">SN</label>
                        <input
                            className="text-center bg-gray-100 border border-gray-300 rounded-md w-full md:w-32"
                            value={serialNumber || ''}
                            onChange={(e) => setSerialNumber(e.target.value)}
                        />
                    </div>

                    <div className="flex flex-row items-center mb-2 md:mb-0 md:mr-4">
                        <label className="italic text-gray-600 mr-2">ft</label>
                        <input
                            type="number"
                            className="text-center bg-gray-100 border border-gray-300 rounded-md w-full md:w-32"
                            value={ftInstalled}
                            onChange={(e) => setFtInstalled(e.target.value)}
                        />
                    </div>

                    <button className="text-blue-500 hover:text-blue-700 md:ml-5">
                        <FontAwesomeIcon icon={faPlus} size="sm" onClick={() => createLightsInstalled(serialNumber ?? null, int.parse(ftInstalled))} />
                    </button>
                </div>

                {lightsInstalledList.map((el, index) => (
                    <div key={index} className="flex flex-col md:flex-row items-center justify-between p-2 mt-3 bg-white rounded-md shadow text-gray-600">
                        <div className="w-full flex flex-col md:flex-row md:items-center">
                            <div className="flex mb-2 md:mb-0 md:mr-4 items-center">
                                <label className="italic mr-2">SN</label>
                                <input
                                    className="bg-gray-200 text-center w-full md:w-32 mx-2 border border-gray-300 rounded-md text-gray-500 cursor-not-allowed"
                                    value={el.serialNumber || ''}
                                    readOnly />
                            </div>

                            <div className="flex items-center">
                                <label className="italic mr-2">ft</label>
                                <input
                                    className="bg-gray-200 text-center w-full md:w-32 mx-2 border border-gray-300 rounded-md text-gray-500 cursor-not-allowed"
                                    value={el.ft}
                                    readOnly />
                            </div>
                        </div>

                        <button className="text-red-500 hover:text-red-700 mt-2 md:mt-0">
                            <FontAwesomeIcon icon={faTrash} onClick={() => deleteLightsInstalled(el)} />
                        </button>
                    </div>
                ))}

                {lightsInstalledList.length && installId ? (
                    <div className="flex flex-col items-center mt-10 bg-white shadow-lg rounded-lg py-4 px-6 w-full md:max-w-md mx-auto">
                        <div className='flex items-center mb-4'>
                            <label className='text-gray-700 text-lg font-medium mr-2'>Job completed?</label>
                            <input
                                className='form-checkbox h-4 w-4 text-blue-500 rounded border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                type='checkbox'
                                onChange={toggleJobCompleted} />
                        </div>

                        <button
                            onClick={submitForm}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
                        >
                            Submit
                        </button>
                    </div>
                ) : null}
            </div>
        </div>
    );
}