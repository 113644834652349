import { ICompany } from ".";
import { ILead } from "./lead";
import { int } from "./primitives";

export interface IContract {
    id: int;
    pandadocContractId: string | null;
    signedDate: Date | null;
    sentDate: Date | null;
    createdAt: Date;
    updatedAt: Date;
    lead?: ILead;
}

export enum DealerAgreementType {
    NEW_DEALER = "new_dealer",
    RENEWAL = "renewal",
}

export interface IDealerAgreement {
    id: int;
    pandadocId: string;
    company: ICompany | null;
    grandTotal: int;
    type: DealerAgreementType;
    name: string;

    createdAt: Date;
}

export interface ICreateDealerAgreementParams {
    pandadocId: string;
    companyId: int | null;
    grandTotal: int;
    type: DealerAgreementType;
    name: string;
    createdAt: Date;
}
export interface IUpdateDealerAgreementParams {
    companyId?: int;
}

export interface ICreateContractParams {
    pandadocContractId: string;
}
