import { IInstall } from "./installs";
import { int } from "./primitives";
import { IRepair } from "./repair";
import { IUser } from "./user";

export interface IPostInstall {
    id: int;
    lightsInstalled: {
        ft: int;
        serialNumber: string | null;
    }[]
    installer: IUser;
    install: IInstall;
    collectedPayment: boolean | null;
    paymentNotes: string | null;
    checklist: IInstallerCheckList | null;

    createdAt: Date;
    deletedAt: Date | null;
    updatedAt: Date;
}

export interface ICreatePostInstall {
    lightsInstalled: {
        ft: int;
        serialNumber: string | null;
    }[]
    installerAccountId: int;
    installId: int;
    collectedPayment: boolean | null;
    paymentNotes: string | null;
}

export interface IUpdatePostInstall {
    lightsInstalled?: {
        ft: int;
        serialNumber: string | null;
    }[]
    installerAccountId?: int;
}

export enum QuestionType {
    INSTALL = "Install",
    REPAIR = "Repair"
}

export enum AnswerType {
    DD = "Dropdown",
    DD_FF = "Dropdown with Freeform",
    FF = "Freeform",
    YN = "Yes/No",
}

export interface IQuestions {
    id: int;
    type: QuestionType;
    answerType: AnswerType;
    sortOrder: int;
    text: string;

    createdAt: Date;
    updatedAt: Date | null;
    deletedAt: Date | null;

    answerOptions?: IAnswerOptions[]
};

export interface IAnswerOptions {
    id: int;
    question: IQuestions;
    sortOrder: int;
    text: string;
};

export interface IInstallerCheckList {
    id: int;
    postInstall: IPostInstall;
    response: {
        question: string;
        answer: string;
    }[];
};

export interface ICreateInstallerCheckList {
    postInstallId: int;
    response: {
        question: string;
        answer: string;
    }[];
};

export interface IRepairCheckList {
    id: int;
    repair: IRepair;
    response: {
        question: string;
        answer: string;
    }[];
    repairTech: IUser;
};

export interface ICreateRepairCheckList {
    repairId: int;
    response: {
        question: string;
        answer: string;
    }[];
    repairTechId: int;
};